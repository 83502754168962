import React from 'react';
import PropTypes from 'prop-types';
import { getMediaPropsFromGatsbyContentfulMedia, getMediaPropsFromContentfulMedia } from 'src/utils/contentful';
import { Media } from 'src/tapestry/core/media';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { withFramework } from 'lib/get-framework';
import { HomeHeroBase } from '../HomeHeroBase';
import { translations } from './HomeHeroEvergreen.translations';
import { heroEvergreen_, media_ } from './HomeHeroEvergreen.module.scss';

const HeroMedia = ({ mediaMap }) => {
  return (
    <div className={media_}>
      <Media
        {...withFramework({
          gatsby: () => getMediaPropsFromGatsbyContentfulMedia(mediaMap.wsMedia_homeHeroEvoEvergreen),
          next: () => getMediaPropsFromContentfulMedia(mediaMap.wsMedia_homeHeroEvoEvergreen),
        })}
        objectFit="contain"
        releaseAspectRatio
        lazyloadThreshold="-35%"
      />
    </div>
  );
};

export const HomeHeroEvergreen = ({ mediaMap, ...props }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  return (
    <HomeHeroBase
      {...props}
      contentClassName={heroEvergreen_}
      heading={useTranslation('home::hero::evergreen::heading')}
      subheading={useTranslation('home::hero::evergreen::subheading')}
      list={useTranslation('home::hero::evergreen::list')}
      media={<HeroMedia mediaMap={mediaMap} />}
    />
  );
};

HomeHeroEvergreen.propTypes = {
  mediaMap: PropTypes.shape({}).isRequired,
};
