import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'src/utils/css';
import { CONTACT_FORM_URL } from 'src/utils/urls';
import { GRID_DEFAULT_CONFIG, RefreshCell, RefreshGrid } from 'src/components/layout/Grid';
import { Subsection } from 'src/components/layout/Subsection';
import { DEFAULT_CONFIG_BY_ELEMENT_TYPE, FluidText } from 'src/components/common/FluidText';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { Markdown } from 'src/tapestry/core/markdown';
import { PricingCard } from 'src/components/common/PricingCards';
import { translations } from './HomeTiers.translations';
import { subheading_, cta_ } from './HomeTiers.module.scss';

const PREMIUM_INDEX = 0;
const GENERATION_INDEX = 1;

export const HomeTiers = ({ className, premiereMedia, generationMedia, ...rest }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);
  const tiers = useTranslation('pricing::home-cards');

  return (
    <Subsection subsectionName="HomeTiers" {...rest}>
      <div className={classNames(className)}>
        <RefreshGrid xs={{ columnGap: 0 }} sm={{ gap: GRID_DEFAULT_CONFIG.gap }}>
          <RefreshCell sm={{ width: 6 }} lg={{ width: 4 }}>
            <FluidText type="h2" {...DEFAULT_CONFIG_BY_ELEMENT_TYPE.h1}>
              {useTranslation('home-tiers::heading')}
            </FluidText>
            <FluidText type="p" className={subheading_}>
              <Markdown
                source={useTranslation('home-tiers::subheading')}
                unwrapElements="paragraph"
              />
            </FluidText>
            <CTALinkButton href={CONTACT_FORM_URL} className={cta_}>
              {useTranslation(`home-tiers::cta::label`)}
            </CTALinkButton>
          </RefreshCell>
          <RefreshCell sm={{ left: 1, width: 6 }} lg={{ left: 'auto', width: 4 }}>
            <PricingCard
              tierId="premium"
              eyebrow={tiers[PREMIUM_INDEX].eyebrow}
              heading={tiers[PREMIUM_INDEX].heading}
              lists={tiers[PREMIUM_INDEX].infoLists}
              media={premiereMedia}
              variant="home"
            />
          </RefreshCell>
          <RefreshCell sm={{ width: 6 }} lg={{ width: 4 }}>
            <PricingCard
              tierId="generation"
              eyebrow={tiers[GENERATION_INDEX].eyebrow}
              heading={tiers[GENERATION_INDEX].heading}
              lists={tiers[GENERATION_INDEX].infoLists}
              media={generationMedia}
              variant="home"
            />
          </RefreshCell>
        </RefreshGrid>
      </div>
    </Subsection>
  );
};

HomeTiers.propTypes = {
  className: PropTypes.string,
};
