import React from 'react';
import PropTypes from 'prop-types';
import { toFieldPath, toObjectId } from '@stackbit/annotations';
import { getMediaPropsFromGatsbyContentfulMedia } from 'src/utils/contentful';
import { Media } from 'src/tapestry/core/media';
import { Section } from 'src/components/layout/Section';
import { SplitHero } from 'src/components/subsections/SplitHero';
import { FluidText } from 'src/components/common/FluidText';
import { ContentfulCTA } from 'src/components/common/ContentfulCTA';
import { withLocaleCheckboxes } from 'src/components/contentful/withLocaleCheckboxes';
import { disclaimer_ } from './ContentfulHomeHero.module.scss';

const ContentfulHomeHeroContent = ({
  contentfulId,
  background,
  sectionEyebrow,
  sectionHeading,
  sectionBody,
  sectionDisclaimer,
  asset,
  customAsset,
  primaryCtaLabel,
  primaryCtaUrl,
  secondaryCtaLabel,
  secondaryCtaUrl,
  hasWideMedia,
  ...props
}) => {
  if (!sectionHeading && !primaryCtaUrl && !asset) {
    return null;
  }

  const disclaimer = sectionDisclaimer?.sectionDisclaimer;

  return (
    <Section
      {...toObjectId(contentfulId)}
      {...props}
      background={background}
      verticalSpacingCollapse="all"
    >
      <SplitHero
        hasWideMedia={hasWideMedia}
        isFullHeight
        heading={
          typeof sectionHeading === 'string'
            ? (
              <FluidText
                type="h2"
                min="ws-display-lg-serif"
                max="ws-display-3xl-serif"
                {...toFieldPath('sectionHeading')}
              >
                {sectionHeading}
              </FluidText>
            )
            : React.isValidElement(sectionHeading) && React.cloneElement(sectionHeading, {
              ...toFieldPath('sectionHeading'),
            })

        }
        eyebrow={sectionEyebrow}
        subheading={
          (sectionBody && typeof sectionBody.sectionBody === 'string') ? (
            <FluidText type="markdown" {...toFieldPath('sectionBody')}>
              {sectionBody?.sectionBody}
            </FluidText>
          ) : sectionBody.sectionBody
        }
        media={
          customAsset ?? (asset && (
            <Media
              releaseAspectRatio
              objectFit="contain"
              lazyload={false}
              {...getMediaPropsFromGatsbyContentfulMedia(asset)}
            />
          ))
        }
        cta={
          primaryCtaUrl && (
            <ContentfulCTA
              size="xxl"
              type="link"
              label={primaryCtaLabel}
              labelFieldPath={toFieldPath('primaryCtaLabel')}
              link={primaryCtaUrl}
              {...toFieldPath('primaryCtaUrl')}
            />
          )
        }
        secondaryCta={
          secondaryCtaUrl && (
            <ContentfulCTA
              type="link"
              variant="secondary"
              size="xxl"
              label={secondaryCtaLabel}
              labelFieldPath={toFieldPath('secondaryCtaLabel')}
              link={secondaryCtaUrl}
              {...toFieldPath('secondaryCtaUrl')}
            />
          )
        }
      >
        {disclaimer && <FluidText all="ws-text-xs" type="markdown" className={disclaimer_} {...toFieldPath('sectionDisclaimer')}>{disclaimer}</FluidText>}
      </SplitHero>
    </Section>
  );
};

ContentfulHomeHeroContent.propTypes = {
  contentfulId: PropTypes.string.isRequired,
  background: PropTypes.string,
  sectionHeading: PropTypes.node.isRequired,
  sectionBody: PropTypes.shape({
    sectionBody: PropTypes.string,
  }),
  sectionDisclaimer: PropTypes.shape({
    sectionDisclaimer: PropTypes.string,
  }),
  asset: PropTypes.shape({}),
  customAsset: PropTypes.node,
  primaryCtaLabel: PropTypes.string,
  primaryCtaUrl: PropTypes.string,
  secondaryCtaLabel: PropTypes.string,
  secondaryCtaUrl: PropTypes.string,
};

export const ContentfulHomeHero = withLocaleCheckboxes(ContentfulHomeHeroContent);
