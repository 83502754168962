import React from 'react';
import { KeyPoints } from '../KeyPoints';

const CONFIG = {
  withSeparators: true,
  itemHeadingFluidTextConfig: {
    min: 'ws-display-md-serif',
    max: 'ws-display-xl-serif',
  },
  itemDetailsFluidTextConfig: { min: 'ws-text-lg', max: 'ws-text-2xl' },
};

export const KeyStats = props => <KeyPoints {...CONFIG} {...props} />;
