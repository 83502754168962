'use client';

import React from 'react';
import Script from 'next/script';
import { Helmet } from 'react-helmet-async';
import { getFramework } from 'lib/get-framework';
import { useTranslationContext } from 'src/components/contexts/TranslationContext';

const SCRIPT_BODY = `
  (function (m, a, z, e) {
    var s, t;
    try {
      t = m.sessionStorage.getItem('maze-us');
    } catch (err) {}

    if (!t) {
      t = new Date().getTime();
      try {
        m.sessionStorage.setItem('maze-us', t);
      } catch (err) {}
    }

    s = a.createElement('script');
    s.src = z + '?apiKey=' + e;
    s.async = true;
    a.getElementsByTagName('head')[0].appendChild(s);
    m.mazeUniversalSnippetApiKey = e;
  })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '30530289-5b0a-4266-8570-d3d5cbfa8f1b');
`;

export const MazeUxrScript = () => {
  const { isGatsby } = getFramework();
  const { isFr } = useTranslationContext();

  if (isFr) return null;

  return isGatsby ? (
    <Helmet>
      <script type="text/javascript" id="maze-uxr-script">{SCRIPT_BODY}</script>
    </Helmet>
  ) : (
    <Script id="maze-uxr-script" strategy="lazyOnload">{SCRIPT_BODY}</Script>
  );
};
