import(/* webpackMode: "eager", webpackExports: ["HomePageBody"] */ "/opt/build/repo/app/[locale]/(home)/_components/index.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/contexts/PageAttributesContext/PageAttributesContext.component.js");
;
import(/* webpackMode: "eager", webpackExports: ["ProductFeatureBody"] */ "/opt/build/repo/src/components/subsections/ProductFeature/components/ProductFeatureBody/ProductFeatureBody.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/subsections/ProductFeature/ProductFeature.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/tapestry/core/media/media.component.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/utils/seo/schema.js");
