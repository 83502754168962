import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'src/components/layout/Section';
import { AppDownloadCTA } from 'src/components/common/AppDownloadCTA';
import { FluidText } from 'src/components/common/FluidText';
import { KeyPoints } from 'src/components/subsections/KeyPoints';
import { SplitHero } from 'src/components/subsections/SplitHero';
import { classNames } from 'src/utils/css';
import { list_ } from './HomeHeroBase.module.scss';

const LIST_HEADING_FLUID_CONFIG = {
  type: 'h2',
  min: 'ws-text-2xl-medium',
  max: 'ws-text-3xl-medium',
};

export const HomeHeroBase = ({
  background,
  heading,
  headingConfig,
  subheading,
  cta = <AppDownloadCTA />,
  secondaryCta,
  media,
  list,
  className,
  ...rest
}) => {
  const defaultHeadingConfig = {
    min: 'ws-display-xl-serif',
    max: 'ws-display-4xl-serif',
  };
  const finalHeadingConfig = headingConfig ?? defaultHeadingConfig;

  return (
    <Section
      background={background}
      verticalSpacingCollapse={list ? 'top' : 'all'}
      className={classNames('overflow-x:clip', className)}
      {...rest}
    >
      <SplitHero
        heading={
          <FluidText type="h1" {...finalHeadingConfig}>
            {heading}
          </FluidText>
        }
        subheading={subheading}
        cta={cta}
        secondaryCta={secondaryCta}
        media={media}
        // quick patch for a deeper issue with subsection auto vertical spacing
        collapseBottomMargin={!list}
      />
      {list && (
        <KeyPoints
          className={list_}
          list={list}
          itemHeadingFluidTextConfig={LIST_HEADING_FLUID_CONFIG}
          centeredItems
          withSeparators
        />
      )}
    </Section>
  );
};

HomeHeroBase.propTypes = {
  /** Can be any of the supported `Section` backgrounds. */
  background: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingConfig: PropTypes.shape(FluidText.propTypes),
  subheading: PropTypes.string.isRequired,
  /** Accepts any type of media node you want to pass in: `Media`, `ProductHeroMedia`, `MediaWithCaption`, etc. */
  media: PropTypes.node.isRequired,
  cta: PropTypes.node,
  secondaryCta: PropTypes.node,
  /** In a format acceptable for `KeyPoints`. */
  list: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};
