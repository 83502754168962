import React from 'react';
import { Helmet } from 'react-helmet-async';
import wordmark from './media/ws-wordmark.svg';

export const OrganizationSchema = () => (
  <Helmet>
    <script type="application/ld+json">
      {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Wealthsimple",
        "description": "Wealthsimple is the smart way to invest, trade, save, spend and file your taxes. Do more with your money with our smart financial tools and expert advice.",
        "image": "${wordmark}",
        "logo": "${wordmark}",
        "url": "https://www.wealthsimple.com",
        "sameAs": [
          "https://twitter.com/wealthsimple",
          "https://www.linkedin.com/company/wealthsimple",
          "https://www.facebook.com/wealthsimple/",
          "https://www.youtube.com/channel/UCbJXYtqMuLwd0S7pi_QGGzw",
          "https://www.instagram.com/wealthsimple/",
          "https://en.wikipedia.org/wiki/Wealthsimple",
          "https://www.crunchbase.com/organization/wealthsimple",
          "https://www.pinterest.com/wealthsimple"
        ],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "80 Spadina Ave",
          "addressLocality": "Toronto",
          "postalCode": "M5V 2J4",
          "addressCountry": "Canada",
          "telephone": "1-855-255-9038",
          "contactType": "customer service",
          "contactOption": "TollFree",
          "areaServed": "CA",
          "availableLanguage": ["en", "fr"]
        }
      }
    `}
    </script>
  </Helmet>
);
