export const translations = {
  'en-ca': {
    'home::hero::evergreen::heading': 'Your money’s worth more',
    'home::hero::evergreen::subheading': `Get the most out of your money with smart investing products and personalized advice to build long-term wealth.`,
    'home::hero::evergreen::list': [
      {
        heading: 'Low fees meet higher yields',
        details: `Your money’s always making more with low-fee investing and high-interest savings.`,
      },
      {
        heading: 'Unmatched access',
        details: `Get sophisticated investment opportunities traditionally reserved for industry insiders and the ultra-wealthy.`,
      },
      {
        heading: 'Smart & simple',
        details: `In just a few taps, set your financial goals in motion, and let our easy-to-use products handle the rest.`,
      },
    ],
  },
  'fr-ca': {
    'home::hero::evergreen::heading': 'Votre argent vaut plus',
    'home::hero::evergreen::subheading': `Tirez le maximum de votre argent avec des produits de placement bien pensés et des conseils personnalisés pour faire croître votre patrimoine.`,
    'home::hero::evergreen::list': [
      {
        heading: 'Frais modiques, rendements élevés',
        details: `Votre argent rapportera toujours plus avec des placements à frais modiques et des épargnes à intérêt élevé.`,
      },
      {
        heading: 'Accès privilégié',
        details: `Accédez à des occasions d'investissement habituellement réservées aux gens de l'industrie et aux ultrariches.`,
      },
      {
        heading: 'Simple et brillant',
        details: `Passez à l'action en quelques clics\u00A0: établissez vos objectifs financiers et laissez nos produits conviviaux faire le travail.`,
      },
    ],
  },
};
