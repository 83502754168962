'use client';

import React from 'react';
import { PRODUCT_INVEST_PATH, PRODUCT_TRADE_PATH, PRODUCT_SPEND_PATH } from 'src/utils/urls';
import { useTranslationContext, useTranslation } from 'src/components/contexts/TranslationContext';
import { AutoGrid } from 'src/components/layout/AutoGrid';
import { RefreshCell } from 'src/components/layout/Grid';
import { Subsection } from 'src/components/layout/Subsection';
import { FeatureBlockBody } from 'src/components/common/FeatureBlockBody';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { FluidText } from 'src/components/common/FluidText';
import { FeatureBlockCopy } from 'src/components/subsections/FeatureBlock';
import { translations } from './ProductFeatureBody.translations';
import { contentContainer_ } from './ProductFeatureBody.module.scss';

export const ProductFeatureBody = ({ product, media, className, ...rest }) => {
  const { appendTranslationKeys } = useTranslationContext();
  appendTranslationKeys(translations);

  const useProductTranslation = key => {
    return useTranslation(`${key}::${product}`);
  };

  const productUrlMap = {
    cash: PRODUCT_SPEND_PATH,
    selfDirected: PRODUCT_TRADE_PATH,
    managedInvesting: PRODUCT_INVEST_PATH,
  };

  return (
    <Subsection subsectionName="ProductFeature" {...rest}>
      <AutoGrid preset="twoCellAlt" className={className}>
        <RefreshCell className={contentContainer_}>
          <FeatureBlockCopy>
            <FeatureBlockBody
              eyebrow={
                <FluidText type="p" min="ws-eyebrow-md" max="ws-eyebrow-lg">
                  {useProductTranslation('subsection::productfeature::eyebrow')}
                </FluidText>
            }
              heading={
                <FluidText type="h3" min="ws-display-md-sans" max="ws-display-xl-sans">
                  {useProductTranslation('subsection::productfeature::heading')}
                </FluidText>
            }
              subheading={
                <FluidText type="markdown">
                  {useProductTranslation('subsection::productfeature::details')}
                </FluidText>
            }
              cta={
                <CTALinkButton href={productUrlMap[product]} variant="secondary" size="xxl">
                  {useProductTranslation('subsection::productfeature::cta')}
                </CTALinkButton>
            }
            />
          </FeatureBlockCopy>
        </RefreshCell>
        <RefreshCell>{media}</RefreshCell>
      </AutoGrid>
    </Subsection>
  );
};
