import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import NextImage from 'next/image';
import { getFramework } from 'lib/get-framework';
import { classNames } from 'src/utils/css';
import { coverImage_, squeezeTransitionSmoother_ } from './CoverImage.module.scss';

const verticalAlignmentToPosition = {
  'flex-start': '50% 0%',
  'flex-end': '50% 100%',
  'center': '50% 50%',
};

export const CoverImage = ({ image, verticalAlignment = 'center', hasSqueezeAbove = true, className }) => {
  if (!image) return null;

  const { isGatsby } = getFramework();

  return isGatsby ? (
    <GatsbyImage
      image={image?.gatsbyImageData}
      alt=""
      loading="lazy"
      objectFit="cover"
      objectPosition={verticalAlignmentToPosition[verticalAlignment]}
      className={classNames(coverImage_, hasSqueezeAbove && squeezeTransitionSmoother_, className)}
    />
  ) : (
    <NextImage
      src={image?.url ?? image?.src}
      width={image.width}
      height={image.height}
      style={{ objectFit: 'cover', objectPosition: verticalAlignmentToPosition[verticalAlignment] }}
      className={classNames(coverImage_, hasSqueezeAbove && squeezeTransitionSmoother_, className)}
    />
  );
};

CoverImage.propTypes = {
  image: PropTypes.shape({}),
  verticalAlignment: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  hasSqueezeAbove: PropTypes.bool,
};
