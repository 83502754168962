import React from 'react';
import PropTypes from 'prop-types';
import { ContentfulNotificationBanner } from 'src/components/common/NotificationBanner';
import { getSeoOnlyContentGatsby } from 'src/utils/contentful';
import { PageLayout } from '../PageLayout';

const ContentfulStandardPageLayout = ({ children, page = {}, notificationBanner, ...props }) => {
  const { entryId, notificationBanner: notificationBanner_ = {} } = page;
  const { metadata } = getSeoOnlyContentGatsby(page);

  return (
    <PageLayout
      notificationBanner={
        <>
          <ContentfulNotificationBanner {...notificationBanner_} />
          {notificationBanner}
        </>
      }
      metadata={metadata}
      entryId={entryId}
      {...props}
    >
      {children}
    </PageLayout>
  );
};

ContentfulStandardPageLayout.propTypes = {
  page: PropTypes.shape({
    entryId: PropTypes.string,
    notificationBanner: PropTypes.shape({}),
  }),
};

export { ContentfulStandardPageLayout };
