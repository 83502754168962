export const translations = {
  'en-ca': {
    'home::product-feature::1::heading': 'Low-fee investing, your way',
    'home::product-feature::2::heading': 'Make more on your everyday earnings',
    'home::trust::heading': 'Trusted by over {{WS_CLIENT_COUNT}} Canadians',
    'home::trust::subheading': `Our business model is simple: put our clients’ interests first. Sure, that means never charging commissions on trades and offering industry-leading interest on your chequing account, but it also means a lot more. Like total transparency into your investments and an innovative cost structure that ensures we only do well when you do.`,
    'home::stats::list': [
      {
        heading: '{{TOTAL_BUSINESS_AUA}}',
        details: 'Assets under administration',
      },
      {
        heading: '{{CASH_CDIC_COVERAGE}}',
        details: 'CDIC coverage on eligible deposits',
      },
      {
        heading: '4x lower',
        details: 'Management fees than the average Canadian mutual fund',
      },
    ],
    'home::support::heading': `Meeting you where you’re at`,
    'home::support::list': [
      {
        heading: 'Speak to an advisor',
        details:
          '[Premium](/pricing) and [Generation](/pricing) clients have access to goal setting and financial planning.',
      },
      {
        heading: 'Call, chat, or email',
        details: `Have account-related inquiries? Our team’s here 7 days a week to connect with you.`,
      },
      {
        heading: 'Self-serve help',
        details:
          'Find answers to your investing questions in our help centre, personal finance how-tos, or right in the app.',
      },
    ],
    'home::support::caption': `**Zoe Wolpert**, ADVISOR, cIM^®^ (Chartered Investment Manager)`,
    'home::final-cta::heading': `Show your money its worth`,
    'home::final-cta::subheading': `Join the {{WS_CLIENT_COUNT}} Canadians choosing Wealthsimple as a trusted place to invest, trade, save, and more.`,
  },
  'fr-ca': {
    'home::product-feature::1::heading': `Des placements à frais modiques, à votre manière`,
    'home::product-feature::2::heading': `Tirez davantage de vos gains quotidiens`,
    'home::trust::heading': 'Adopté par plus de {{WS_CLIENT_COUNT}} de Canadien.nes',
    'home::trust::subheading': `Notre modèle d'affaires est simple : les intérêts de la clientèle d'abord. Oui, cela signifie de ne pas facturer de commission sur les opérations boursières et d'offrir des taux d'intérêt parmi les meilleurs de l'industrie. Mais c'est aussi plus que ça\u00A0: une transparence totale de nos placements et une politique tarifaire innovante qui assure que notre succès soit conditionnel au vôtre.`,
    'home::stats::list': [
      {
        heading: '{{TOTAL_BUSINESS_AUA}}',
        details: 'Biens administrés',
      },
      {
        heading: '{{CASH_CDIC_COVERAGE}}',
        details: 'Couverture de la SADC sur les dépôts admissibles',
      },
      {
        heading: '4\u00A0x plus bas',
        details: 'Nos frais de gestion par rapport à ceux du fonds commun moyen au Canada',
      },
    ],
    'home::support::heading': `Nous vous retrouvons là où vous êtes`,
    'home::support::list': [
      {
        heading: `Parlez à l'équipe-conseil`,
        details: `La clientèle [Avantage](/pricing) et [Génération](/pricing) a accès à des séances de planification financière avec un.e conseiller.ère.`,
      },
      {
        heading: `Par téléphone, clavardage ou courriel`,
        details: `Des questions sur vos comptes? Notre équipe est là sept jours sur sept pour vous aider.`,
      },
      {
        heading: 'Centre d’aide libre-service',
        details: `Des réponses à vos questions sur les placements ou les finances personnelles dans notre centre d'aide ou directement dans l'application.`,
      },
    ],
    'home::support::caption': `**Zoe Wolpert**, conseillère, CIM^MD^ (gestionnaire de placements agréée)`,
    'home::final-cta::heading': `Réalisez le plein potentiel de votre argent`,
    'home::final-cta::subheading': `Joignez-vous à plus de {{WS_CLIENT_COUNT}} de Canadien.nes qui font confiance à Wealthsimple pour placer leur argent, investir, épargner et plus encore.`,
  },
};
