export const translations = {
  'en-ca': {
    'subsection::productfeature::eyebrow::managedInvesting': `Managed Investing`,
    'subsection::productfeature::heading::managedInvesting': `Custom portfolios, designed by experts`,
    'subsection::productfeature::details::managedInvesting': `Professionally managed and tailored to your goals, we'll diversify your investments to maximize returns and minimize risk.`,
    'subsection::productfeature::cta::managedInvesting': `Make me a portfolio`,
    'subsection::productfeature::eyebrow::selfDirected': `Self-directed Investing`,
    'subsection::productfeature::heading::selfDirected': `Take full control of your trades`,
    'subsection::productfeature::details::selfDirected': `Choose from thousands of commission-free stocks and ETFs, or buy, sell, and stake {{CRYPTO_COIN_COUNT_LOWER_BOUND}}+ [cryptocurrencies](/crypto).`,
    'subsection::productfeature::cta::selfDirected': `Start trading`,
    'subsection::productfeature::eyebrow::cash': `Cash`,
    'subsection::productfeature::heading::cash': `Extra high interest meets extra perks`,
    'subsection::productfeature::details::cash': `Get a minimum {{CASH_INTEREST_RATE_CORE}} interest, exclusive investor rewards, no monthly fees, and so much more with Canada’s highest-interest chequing account.`,
    'subsection::productfeature::cta::cash': `Start earning`,
  },
  'fr-ca': {
    'subsection::productfeature::eyebrow::managedInvesting': `Placements gérés`,
    'subsection::productfeature::heading::managedInvesting': `Des portefeuilles sur mesure conçus par des spécialistes`,
    'subsection::productfeature::details::managedInvesting': `Gérés par des professionnel.les et adaptés à vos objectifs, nos portefeuilles bien diversifiés maximisent vos rendements et réduisent le risque au minimum.`,
    'subsection::productfeature::cta::managedInvesting': `Créer mon portefeuille`,
    'subsection::productfeature::eyebrow::selfDirected': `Placements autonomes`,
    'subsection::productfeature::heading::selfDirected': `Prenez le contrôle de vos opérations`,
    'subsection::productfeature::details::selfDirected': `Choisissez parmi des milliers d'actions et FNB sans commission, ou achetez, vendez et immobilisez plus de {{CRYPTO_COIN_COUNT_LOWER_BOUND}} [cryptomonnaies](/crypto).`,
    'subsection::productfeature::cta::selfDirected': `Commencer mes placements`,
    'subsection::productfeature::eyebrow::cash': `Dépenses et épargne`,
    'subsection::productfeature::heading::cash': `Plus d'intérêts et plus d'avantages`,
    'subsection::productfeature::details::cash': `Profitez d'un taux d'intérêt minimum de {{CASH_INTEREST_RATE_CORE}}, de récompenses exclusives et plus encore avec le compte-chèque offrant le meilleur taux d'intérêt au Canada - le tout sans frais mensuels.`,
    'subsection::productfeature::cta::cash': `Tirez-en plus`,
  },
};
