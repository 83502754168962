import PropTypes from 'prop-types';
import React from 'react';
import { classNames } from 'src/utils/css';
import { stack_ } from './Stack.module.scss';

export const Stack = ({ children, defaultSpacing, className, as, style, ...props }) => {
  const ComponentWrapper = as || 'div';
  const styleObject = {
    ...style,
    ...defaultSpacing ? { '--stack-spacing': defaultSpacing } : {},
  };
  return (
    <ComponentWrapper className={classNames(stack_, className)} style={styleObject} {...props}>
      {children}
    </ComponentWrapper>
  );
};

Stack.propTypes = {
  children: PropTypes.node,
  defaultSpacing: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  style: PropTypes.shape({}),
};
