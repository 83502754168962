import { PRICING_PATH } from 'src/utils/urls';

export const translations = {
  'en-ca': {
    'home-tiers::heading': `Benefits that grow with you`,
    'home-tiers::subheading': `Bringing all your investments to Wealthsimple means better access to exclusive [perks](${PRICING_PATH}).`,
    'home-tiers::cta::label': `Contact our team`,
    'pricing::home-cards': [
      {
        id: 'premium',
        eyebrow: '{{AUM_THRESHOLD_PREMIUM}} in assets',
        heading: 'Premium',
        infoLists: [
          {
            items: [
              `{{MANAGED_INVEST_FEE_PREMIUM}} management fees on managed investing accounts`,
              `{{CASH_INTEREST_RATE_PREMIUM}} interest on your Cash account`,
              `Goal setting with an advisor `,
            ],
          },
        ],
      },
      {
        id: 'generation',
        eyebrow: '{{AUM_THRESHOLD_GENERATION}} in assets',
        heading: 'Generation',
        infoLists: [
          {
            items: [
              `{{MANAGED_INVEST_FEE_GENERATION_MIN}}*–{{MANAGED_INVEST_FEE_GENERATION_MAX}} management fees on managed investing accounts`,
              `{{CASH_INTEREST_RATE_GENERATION}} interest on your Cash account`,
              `Dedicated team of advisors`,
            ],
          },
        ],
      },
    ],
  },
  'fr-ca': {
    'home-tiers::heading': `Des avantages qui évoluent avec vous`,
    'home-tiers::subheading': `Obtenez plus [d'avantages](${PRICING_PATH}) exclusifs en rassemblant tous vos placements chez Wealthsimple.`,
    'home-tiers::cta::label': `Contactez-nous`,
    'pricing::home-cards': [
      {
        id: 'premium',
        eyebrow: `{{AUM_THRESHOLD_PREMIUM}} d'actifs`,
        heading: 'Avantage',
        infoLists: [
          {
            items: [
              `Frais de gestion de {{MANAGED_INVEST_FEE_PREMIUM}} sur les comptes de placement gérés`,
              `{{CASH_INTEREST_RATE_PREMIUM}} d'intérêts sur votre compte Cash`,
              `Établissement d'objectifs avec une conseillère ou un conseiller`,
            ],
          },
        ],
      },
      {
        id: 'generation',
        eyebrow: `{{AUM_THRESHOLD_GENERATION}} d'actifs`,
        heading: 'Génération',
        infoLists: [
          {
            items: [
              `Frais de gestion de {{MANAGED_INVEST_FEE_GENERATION_MIN}}*–{{MANAGED_INVEST_FEE_GENERATION_MAX}} sur les comptes de placement gérés`,
              `{{CASH_INTEREST_RATE_GENERATION}} d'intérêts sur votre compte Cash`,
              `Une équipe-conseil dévouée`,
            ],
          },
        ],
      },
    ],
  },
};
