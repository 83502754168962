import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, useTranslationContext } from 'src/components/contexts/TranslationContext';
import { useUserAttributesContext } from 'src/components/contexts/UserAttributesContext';
import { AppDownloadCTA } from 'src/components/common/AppDownloadCTA';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { translations } from './ContentfulCTA.translations';

const ContentfulCTA = ({ label, mobileLabel, link, labelFieldPath, ...props }) => {
  const { appendTranslationKeys } = useTranslationContext();
  const { isMobileAppEligible } = useUserAttributesContext();
  appendTranslationKeys(translations);

  const defaultLinkLabel = useTranslation('contentful_cta::defaults::cta::link::label');

  const withFieldPath = useCallback(
    label_ => (labelFieldPath && label_ ? <span {...labelFieldPath}>{label_}</span> : label_),
    [labelFieldPath]
  );

  const resolvedLabel = isMobileAppEligible && mobileLabel ? mobileLabel : label;
  const mobileLabelWithFieldPath = mobileLabel && withFieldPath(mobileLabel);
  const desktopLabelWithFieldPath = label && withFieldPath(label);

  const isAppsFlyerLink = link && link.includes('app.wealthsimple.com');
  // If there is no link provided, default to app download
  if (!link) {
    return (
      <AppDownloadCTA
        mobileLabel={mobileLabelWithFieldPath || desktopLabelWithFieldPath}
        nonMobileLabel={desktopLabelWithFieldPath}
        {...props}
      />
    );
  }
  // If there is no desktop label provided, but the url is an AppsFlyer link, use the default app download label for desktop and pass the mobile label if provided
  if (!label && isAppsFlyerLink) {
    return (
      <AppDownloadCTA
        mobileUrl={link}
        nonMobileUrl={link}
        mobileLabel={mobileLabelWithFieldPath}
        {...props}
      />
    );
  }

  // Link
  return (
    <CTALinkButton href={link} {...props}>
      {withFieldPath(resolvedLabel) || defaultLinkLabel}
    </CTALinkButton>
  );
};

export { ContentfulCTA };

ContentfulCTA.propTypes = {
  label: PropTypes.node,
  mobileLabel: PropTypes.node,
  link: PropTypes.string,
  labelFieldPath: PropTypes.shape({
    'data-sb-field-path': PropTypes.string,
  }),
};
