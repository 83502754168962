import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useInView } from 'react-intersection-observer';
import { classNames } from 'src/utils/css';
import { WATERFALL_ANIMATION_DELAY } from 'src/components/subsections/FeatureBlock';
import { useMotionOptOutContext } from 'src/components/contexts/MotionOptOutContext';
import { FluidText } from '../FluidText';
import { FeatureBlockDetailsListItem } from '../FeatureBlockDetailsListItem';
import { listItem_, listHeading_, cta_ } from './FeatureBlockDetailsList.module.scss';

export const FeatureBlockDetailsList = ({
  className,
  heading: listHeading,
  list,
  headingConfig,
  detailsConfig,
  reverseWaterfallAnimation,
  inViewThreshold = 0.5,
  cta,
}) => {
  const [viewRef, inView] = useInView({ triggerOnce: true, threshold: inViewThreshold });
  const { prefersReducedMotion } = useMotionOptOutContext();
  return (
    <div
      ref={viewRef}
      className={classNames(
        className,
        inView && 'waterfall-trigger',
        reverseWaterfallAnimation && !prefersReducedMotion && 'waterfall-transition'
      )}
    >
      {isString(listHeading) ? (
        <FluidText className={listHeading_} type="h2">
          {listHeading}
        </FluidText>
      ) : (
        listHeading
      )}
      {list.map(({ heading, details, entryId }, index) => (
        <FeatureBlockDetailsListItem
          key={entryId || heading}
          className={classNames('waterfall-element', listItem_)}
          style={{ transitionDelay: `${WATERFALL_ANIMATION_DELAY * index}s` }}
          heading={heading}
          details={details}
          headingConfig={headingConfig}
          detailsConfig={detailsConfig}
          entryId={entryId}
        />
      ))}
      {cta && <div className={cta_}>{cta}</div>}
    </div>
  );
};

FeatureBlockDetailsList.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      details: PropTypes.string,
      entryId: PropTypes.string,
    })
  ).isRequired,
  cta: PropTypes.node,
  headingConfig: PropTypes.shape(FluidText.propTypes),
  detailsConfig: PropTypes.shape(FluidText.propTypes),
  reverseWaterfallAnimation: PropTypes.bool,
  inViewThreshold: PropTypes.number,
};
